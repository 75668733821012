.service-bar {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  list-style: none;
}

.service-link {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: black;
  padding: 0.7rem 1rem;
  border-radius: 6px;
  text-align: center;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 50ms linear 0s;
}

.service-link:hover,
.service-link.active {
  border-bottom-color: #f00;
}
.service-link-text {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 1375px) {
  .service-bar {
    display: none;
  }
}
