.service {
  margin: 4rem 6rem;
  color: #2a2a2a;
}

.service h1 {
  font-size: 3.1rem;
  text-align: center;
}

.service-para {
  text-align: center;
}

.service a {
  text-decoration: none;
  color: #2a2a2a;
}

.servicecard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.servicecard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.s-card {
  width: 32%;
  text-align: start;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem;
}

.s-image {
  height: 70px;
  overflow: hidden;
  border-radius: 7px;
}

.s-card:hover {
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.25);
}

.s-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
}

@media screen and (max-width: 850px) {
  .service {
    margin: 4rem 2rem;
  }

  .servicecard {
    flex-direction: column;
  }

  .s-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 650px) {
  .service h1 {
    font-size: 2.1rem;
    text-align: center;
  }
  .service {
    margin: 2rem 1rem;
  }
}
