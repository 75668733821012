.gallery {
  margin: 4rem 6rem;
  color: #2a2a2a;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}

.gallery-card h2 {
  width: 100%;
  text-align: center;
  border-top: 5px solid #cd0002;
  padding-top: 2rem;
}

.gallery-header h1 {
  font-size: 3.1rem;
  text-align: center;
}

.gallery-header p {
  text-align: center;
}

.p-image {
  width: 100%;
  -webkit-transition: all 4s ease;
  transition: all 4s erase;
  cursor: pointer;
  margin-bottom: 12px;
}

.p-image:hover {
  filter: opacity(0.8);
}

.p-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 991px) {
  .gallery {
    margin: 4rem 2rem;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
    padding: 0 10px;
  }
}

@media screen and (max-width: 650px) {
  .gallery-header h1 {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 480px) {
  .gallery {
    margin: 2rem 1rem;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    padding: 0 6px;
  }
  .gallery-header h1 {
    font-size: 2.1rem;
  }
}

/* model */

.model {
  width: 100%;
  height: 90vh;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model.open i {
  position: fixed;
  font-size: 3rem;
  top: 10px;
  right: 50px;
  color: black;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  cursor: pointer;
}

.model img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}
