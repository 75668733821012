.fullService {
  padding: 50px;
}

.serviceOCard {
  margin: 0 6rem 4rem 6rem;
  background-color: #fcfcfc;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}

.serviceCard-text {
  padding-top: 4rem;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  text-align: start;
  font-size: 1.1rem;
}

.serviceOCard h1 {
  font-size: 3.1rem;
  font-weight: bold;
  text-align: start;
  color: rgb(0, 0, 0);
}

.serviceOCard li {
  color: #cd0002;
  text-decoration: none;
  list-style: none;
  font-weight: bold;
  padding-bottom: 10px;
}

.serviceOCard h5 {
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.2rem;
  font-weight: lighter;
}

.serviceOCard p {
  font-size: 1.2rem;
  padding-bottom: 1.2rem;
}

.imageServiceCard {
  width: 50%;
  margin-top: 55px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}

.imageServiceCard img {
  margin: 5px;
  border-radius: 6px;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgb(0, 0, 0, 0.19);
}

@media screen and (max-width: 1100px) {
  .serviceOCard {
    margin: 2rem 4rem;
    flex-direction: column;
  }

  .serviceCard-text {
    font-size: 1rem;
    width: 100%;
    padding: 10px;
  }
  .serviceOCard h1 {
    font-size: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .imageServiceCard {
    width: 100%;
    /* padding-top: 1rem; */
  }
}

@media screen and (max-width: 950px) {
  .serviceOCard {
    margin: 1rem 2rem;
    flex-direction: column;
  }
  .serviceOCard h1 {
    font-size: 2.1rem;
    padding-bottom: 1rem;
  }
  .imageServiceCard img {
    margin-top: 0;
  }
}
