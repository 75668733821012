.NavbarItems {
  z-index: 1000;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 85px;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
}

.navbar-logo {
  cursor: pointer;
}

.navbar-logo img {
  margin-top: 15px;
  height: 65px;
  width: 100%;
  min-width: 165px;
  max-width: 200px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
}

.nav-links {
  text-decoration: none;
  color: #cd0002;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.7rem 1rem;
  white-space: nowrap;
  border-radius: 4px;
}

.nav-links:hover {
  background: #cd0002;
  color: #fff;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.contact {
  color: white;
  background: #880e0e;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.2 ease-in-out;
}

.contact:hover {
  background-color: #880e0e;
  transform: scale(1.2);
}

.menu-icons {
  display: none;
}

@media screen and (max-width: 1000px) {
  .main {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .NavbarItems {
    z-index: 99;
    margin: 0;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: white;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    align-items: stretch;
    opacity: 0;
    margin: 0;
    padding: 80px 0 30px 0;
    transition: 0.3s ease-in-out;
    text-align: center;
    border-radius: 10px;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  .navbar-logo img {
    margin: 0;
  }
  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 1.2rem 0;
  }

  .nav-links:hover {
    color: #cc0404;
    font-weight: 800;
    transition: none;
    width: 80%;
    margin: auto;
    background-color: white;
  }

  .contact {
    display: block;
    text-align: center;
    margin: auto;
    width: 80%;
    border-radius: 4px;
    background-color: #880e0e;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .menu-icons {
    display: block;
    cursor: pointer;
  }

  .menu-icons i {
    font-size: 1.5rem;
    color: #cd0002;
    position: absolute;
    margin: 0;
    top: 30px;
    right: 40px;
  }
}
