.footer {
  background: #d1d1d1;
  padding: 2rem 6rem 1rem;
  color: rgb(0, 0, 0);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}

.footer .footer-left {
  width: 15%;
  align-items: center;
}

.footer .footer-company-name {
  max-width: 200px;
  color: black;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.footer .footer-center {
  width: 35%;
}

.footer .first-center div:hover {
  transform: scale(1.02);
}

.footer .second-center {
  width: 25%;
}

.footer .footer-center i {
  color: black;
  font-size: 1.5rem;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer .footer-center p {
  display: inline-block;
  color: black;
  vertical-align: middle;
  font-weight: normal;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  line-height: 2;
}

.footer .footer-center p a {
  text-decoration: none;
  color: black;
}

.footer .footer-center h5 {
  color: black;
  font-size: 1.3rem;
  text-align: center;
  width: 38px;
  height: 38px;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}
.footer .nipAndRegon {
  margin: 0;
  display: flex;
  vertical-align: middle;
  text-align: center;
  align-items: center;
}

.regon p {
  padding-left: 30px;
  display: inline-block;
}

.footer .footer-right {
  width: 25%;
}

.footer .footer-company-about {
  margin: 0;
}

@media screen and (max-width: 1200px) {
  .footer .footer-left {
    display: none;
  }
  .footer .second-center {
    width: 30%;
  }
  .footer .footer-right {
    width: 35%;
  }
}

@media screen and (max-width: 983px) {
  .footer .footer-right,
  .footer .footer-left {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .footer .footer-center {
    display: block;
    width: 100%;
    padding-left: 15%;
    text-align: left;
    margin: 0;
  }

  .footer .footer-center i {
    margin-left: 0;
  }
  .footer .footer-center h5 {
    margin-left: 0;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    padding: 1rem 2rem;
  }
  .footer .footer-center {
    display: block;
    width: 100%;
    padding-left: 0;
    text-align: left;
    margin: 0;
  }
  .footer .footer-left,
  .footer .footer-right {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
