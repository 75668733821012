.form-container {
  margin: 4rem 6rem;
  color: black;
  display: flex;
}

.form-container h1 {
  text-align: center;
}

.contact-icons {
  margin: 4rem 0rem;
}

.contact-icons i {
  margin-right: 1rem;
}

.icon {
  background-color: #fcfcfc;
  border: #cd0002 solid 2px;
  padding: 1rem;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: row;
  margin: 1rem;
  cursor: pointer;
  color: black;
}

.contact-href {
  text-decoration: none;
  color: BLACK;
}

.form-container form {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
}

.form-container input {
  height: 2rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  border: 1px solid rgb(0, 0, 0, 0.26);
}

.form-container textarea {
  padding: 0 1rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  border: 1px solid rgb(0, 0, 0, 0.26);
}

.form-container .message {
  padding: 1rem;
  min-height: 150px;
  text-align: start;
}

.form-container button {
  display: inline;
  font-weight: bold;
  padding: 0.9rem;
  border: none;
  color: white;
  background: #d91406;
  border-radius: 0.3rem;
  width: 60%;
  margin: auto;
}

.form-container button:hover {
  transform: scale(1.1);
}

.heading {
  text-align: center;
  padding-top: 8rem;
}

@media screen and (max-width: 850px) {
  .form-container {
    margin: 2rem 1rem;
    flex-direction: column;
  }

  .form-container form {
    width: 90%;
    padding-top: 1rem;
  }
}
